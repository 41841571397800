import React from 'react'
import SbEditable from 'storyblok-react'
import renderBloks from '@renderBloks'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Text, H5 } from '@system'
import font from '@helpers/font'

const { makeResponsiveFontSizes } = font

const useStyles = makeStyles((theme) => {
  return {
    title: {
      textAlign: 'flex-start',
      fontWeight: theme.typography.fontWeightBold,
      ...makeResponsiveFontSizes(20, 11),
      marginBottom: '5px',
    },
    description: {
      textAlign: 'flex-start',
      fontWeight: theme.typography.fontWeightRegular,
      ...makeResponsiveFontSizes(16, 11),
    },
    cta: {
      paddingTop: '5px',
    },
    box: {
      maxWidth: '245px',
      margin: 0,
      justifyContent: 'space-around',
      height: 'auto',
      marginBottom: ({ deviceType }) =>
        deviceType === 'desktop' ? '50px' : '10px',
      cursor: 'pointer',
      color: ({ selected }) =>
        selected ? theme.palette.primary.main : theme.palette.text.tertiary,
      '&:hover': {
        color: `${theme.palette.primary.main} !important`,
      },
      [theme.breakpoints.down('md')]: {
        paddingBottom: ({ deviceType }) =>
          deviceType === 'desktop' ? '30px' : '2px',
        width: '250px',
        maxWidth: '290px',
        color: `${theme.palette.text.tertiary} !important`,
      },
    },
  }
})

const DeviceFrameFeature = (props) => {
  const { selected, blok, deviceType } = props
  const classes = useStyles({ selected, deviceType })

  return (
    <SbEditable content={blok}>
      <Box className={classes.box}>
        <H5 className={classes.title}>{blok.featureName}</H5>
        <Text className={classes.description}>{blok.description}</Text>
        <Box className={classes.cta}>{renderBloks(props.blok.cta)}</Box>
      </Box>
    </SbEditable>
  )
}

export default DeviceFrameFeature
